<template>
  <div class="mx-10">
    <v-row>
      <v-select
        color="#f60076"
        v-model="selectedDeviceCategory"
        :items="deviceCategoryItems"
        label="Device category"
        outlined
      ></v-select>
    </v-row>
    <v-row>
      <v-select
        color="#f60076"
        v-model="selectedDeviceBrandAndModel"
        :items="deviceBrandAndModelItems"
        label="Device brand and model"
        outlined
        multiple
      ></v-select>
    </v-row>
    <v-row>
      <v-select
        color="#f60076"
        label="Hospital"
        :items="hospitalItems"
        v-model="selectedHospital"
        outlined
      ></v-select>
    </v-row>
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Internal device code"
        outlined
        v-model="deviceCode"
      >
      </v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Serial number / mac address"
        outlined
        v-model="serialNumMacAdd"
      >
      </v-text-field>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deviceCategoryItems: ["TRACKER", "ENVIRONMENT"],
      selectedDeviceCategory: null,
      deviceBrandAndModelItems: [
        "Garmin - Vivoactive 4",
        "Atmotube - Atmotube pro",
      ],
      selectedDeviceBrandAndModel: null,
      hospitalItems: ["SERMAS", "iMM", "MNDN-PV", "UNITO"],
      selectedHospital: null,
      deviceCode: "",
      serialNumMacAdd: "",
    };
  },
};
</script>
