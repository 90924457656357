<template>
  <v-app>
    <template v-if="$route.path !== '/'">
      <v-app-bar color="gray" flat>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <v-img
          class="mx-2 logo-image"
          :src="require('@/assets/logo.png')"
          max-width="120"
          contain
        ></v-img>
        <v-spacer></v-spacer>
        <div>DATA MANAGEMENT AND EXPLORATION TOOL</div>
        <v-spacer></v-spacer>

        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-account</v-icon>
                </template>
                <span>{{ email }}</span>
              </v-tooltip>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in accountItems"
              :key="index"
              @click="handleClick(item)"
            >
              <v-list-item-content>
                {{ item }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <router-link to="/" class="router-link">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Login</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>
    <v-main style="min-height: 860px">
      <router-view />
    </v-main>

    <v-footer v-if="!$route.path.includes('')" color="indigo lighten-2">
      <v-col class="text-center" cols="12" style="color: white">
        {{ new Date().getFullYear() }} — <strong>Brainteaser</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import services from "./rest/Services";
import router from "./router";
import state from "./state/state";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
    state: state,
    accountItems: ["Account", "Logout"],
  }),
  methods: {
    logout: function () {
      services
        .logout()
        .then((response) => {
          console.log("response :>> ", response);
          localStorage.removeItem("jwt");
          router.push("/");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            services
              .refreshToken({ jwt: localStorage.getItem("jwt") })
              .then((response) => {
                localStorage.setItem("jwt", response.data.jwt);
                this.logout();
              })
              .catch((error) => {
                console.log("Refresh token failed", error);
                localStorage.removeItem("jwt");
                router.push("/");
              });
          } else {
            console.log("Error:", error);
          }
        });
    },
    handleClick(item) {
      console.log("Clicked item:", item);
      switch (item) {
        case "Account":
          console.log("Account");
          break;
        case "Logout":
          this.logout();
          break;

        default:
          break;
      }
    },
  },
  computed: {
    email: function () {
      return this.state.account.email;
    },
  },
};
</script>

<style>
.router-link {
  text-decoration: none;
}
@media only screen and (max-width: 320px) {
  .name-div {
    display: none;
  }
}
@media only screen and (min-width: 320px) {
  .name-div {
    max-width: 150px;
    font-size: 10px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 400px) {
  .name-div {
    max-width: 200px;
    font-size: 12px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .name-div {
    max-width: 400px;
    font-size: 14px;
    margin-left: 15px;
  }
}
</style>
