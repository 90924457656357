import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  }
]

const router = new VueRouter({
  routes
})

//global before navigation guard
router.beforeEach((to, from, next) => {
  const jwt = localStorage.getItem('jwt')
  if (to.name !== 'Login' && !jwt) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
