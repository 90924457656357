<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5 lg4 xl3>
        <div class="form-header py-5">
          <v-img
            class="mx-auto logo-image mb-2"
            :src="require('@/assets/logo.png')"
            max-width="200"
            contain
          ></v-img>
          <h5 style="font-weight: normal">
            DATA MANAGEMENT AND EXPLORATION TOOL
          </h5>
        </div>

        <v-card class="px-5 py-3" elevation="1">
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="person"
                name="access_code"
                label="Access code"
                type="text"
                color="#f60076"
                v-model="access_code"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
                color="#f60076"
                v-model="password"
              ></v-text-field>
              <div class="d-flex justify-center">
                <v-radio-group v-model="role" row>
                  <v-radio
                    color="#f60076"
                    label="Study admin"
                    value="studyAdmin"
                  ></v-radio>
                  <v-radio
                    color="#f60076"
                    label="Researcher"
                    value="researcher"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-form>
          </v-card-text>
          <div class="d-flex justify-center">
            <v-card-actions>
              <v-btn
                @click="login"
                large
                class="white--text"
                color="#f60076"
                width="120px"
                >LOGIN</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
        <div style="border: 1px solid transparent; height: 100px">
          <v-alert
            class="mt-4"
            v-model="alert"
            border="right"
            colored-border
            type="error"
            elevation="2"
            dismissible
          >
            User account not found
          </v-alert>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
//import state from "../state/state";
import services from "../rest/Services";
import router from "../router/index.js";

export default {
  name: "Login",
  props: {
    source: String,
  },
  data: () => ({
    alert: false,
    access_code: "",
    password: "",
    role: null,
  }),
  methods: {
    login: function () {
      console.log("this is role: ", this.role);
      services
        .login({
          access_code: this.access_code,
          password: this.password,
        })
        .then((response) => {
          //TODO: check if the user has a selected role
          localStorage.setItem("jwt", response.data.jwt);
          sessionStorage.setItem(
            "account",
            JSON.stringify(response.data.account)
          );
          router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          this.alert = true;
        });
    },
  },
};
</script>

<style>
.form-header {
  text-align: center;
}
.login-label {
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
}
.active-radio-btn {
  color: #f60076;
}
</style>
