<template>
  <highcharts
    :constructor-type="'stockChart'"
    :options="chartOptions"
    ref="chart"
  ></highcharts>
</template>

<script>
export default {
  props: {
    histogramData: Object,
  },
  data() {
    return {
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
        },
        plotOptions: {
          series: {
            animation: false,
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        rangeSelector: {
          enabled: false,
          inputEnabled: false,
          selected: 4,
        },
        scrollbar: {
          enabled: true,
        },
        navigator: {
          enabled: true,
        },
        xAxis: {
          visible: true,
        },
        series: [this.histogramData],
      },
    };
  },
};
</script>
