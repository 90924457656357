<template>
  <v-form ref="form">
    <v-row>
      <v-col sm="6" md="3">
        <select-with-search
          :items="questionnaireTypes"
          :label="questionnaireTypesLabel"
        />
      </v-col>
      <v-col sm="6" md="3">
        <select-with-search
          :items="questionnaireSubmitted"
          :label="questionnaireSubmittedLabel"
        />
      </v-col>

      <v-col sm="6" md="3">
        <v-menu
          v-model="dateFromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="dateFrom"
              label="Latest questionnaire submitted from"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="dateFrom"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="6" md="3">
        <v-menu
          v-model="dateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="dateTo"
              label="Latest questionnaire submitted to"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="dateTo"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectWithSearch from "./SelectWithSearch.vue";

export default {
  components: {
    SelectWithSearch,
  },
  data() {
    return {
      dateFromMenu: false,
      dateToMenu: false,
      dateFrom: null,
      dateTo: null,
      questionnaireTypes: [
        { name: "0", value: "0" },
        { name: "1-2", value: "1-2" },
        { name: "3-4", value: "3-4" },
        { name: "5-6", value: "5-6" },
        {
          name: "Less than all questionnaire types",
          value: "Less than all questionnaire types",
        },
        { name: "All questionnaire types", value: "All questionnaire types" },
      ],
      questionnaireTypesLabel: "Different questionnaire types submitted",
      questionnaireSubmitted: [
        { name: "Today", value: "Today" },
        { name: "Last 1-3 days", value: "Last 1-3 days" },
        { name: "Last 3 days - week", value: "Last 3 days - week" },
        { name: "Last week - month", value: "Last week - month" },
        { name: "Last month - 3 months", value: "Last month - 3 months" },
        { name: "Last 3 months - 6 months", value: "Last 3 months - 6 months" },
        { name: "More than 6 months", value: "More than 6 months" },
      ],
      questionnaireSubmittedLabel: "Latest questionnaire submitted",
    };
  },
};
</script>
