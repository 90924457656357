<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <v-select
          color="#f60076"
          v-model="selectedDeviceCategory"
          :items="deviceCategoryItems"
          label="Device category"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>

      <v-col>
        <v-select
          color="#f60076"
          v-model="selectedDeviceInstallationStatus"
          :items="deviceInstallationStatusItems"
          label="Device installation status"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          color="#f60076"
          v-model="selectedDeviceBrandAndModel"
          :items="deviceBrandAndModelItems"
          label="Device brand and model"
          :item-text="combinedDeviceBrandAndModelText"
          return-object
          outlined
          dense
          clearable
        ></v-select>
      </v-col>

      <v-col
        ><v-select
          color="#f60076"
          label="Hospital"
          :items="hospitalItems"
          v-model="selectedHospital"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          color="#f60076"
          placeholder="Internal device code"
          outlined
          v-model="internalCode"
          dense
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          color="#f60076"
          placeholder="Serial number"
          outlined
          v-model="serialNumber"
          dense
          clearable
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import EventBus from "../components/EventBus";

export default {
  components: {},
  props: {
    deviceMetadata: Object,
  },
  created: function () {
    EventBus.$on("resetDeviceFilterForm", () => {
      this.resetForm();
    });
  },
  mounted: function () {
    this.deviceBrandAndModelItems = this.deviceMetadata.device_brand_and_models;
    this.deviceCategoryItems = this.deviceMetadata.device_category;
    this.hospitalItems = this.deviceMetadata.clinical_center;
    this.deviceInstallationStatusItems = this.deviceMetadata.device_status;
  },
  data() {
    return {
      deviceCategoryItems: [],
      selectedDeviceCategory: null,
      deviceBrandAndModelItems: [],
      selectedDeviceBrandAndModel: null,
      deviceInstallationStatusItems: [],
      selectedDeviceInstallationStatus: null,
      internalCode: null,
      serialNumber: null,
      hospitalItems: [],
      selectedHospital: null,
    };
  },
  methods: {
    resetForm: function () {
      this.selectedDeviceCategory = null;
      this.selectedDeviceBrandAndModel = null;
      this.selectedDeviceInstallationStatus = null;
      this.internalCode = null;
      this.serialNumber = null;
      this.selectedHospital = null;
    },
  },
  computed: {
    currentDeviceFilters: function () {
      return {
        device_category: this.selectedDeviceCategory,
        device_status: this.selectedDeviceInstallationStatus,
        brand_and_model: this.selectedDeviceBrandAndModel,
        internal_device_code: this.internalCode,
        serial_number: this.serialNumber,
        hospital_center: this.selectedHospital,
      };
    },

    combinedDeviceBrandAndModelText() {
      return (item) => `${item.device_brand} ${item.device_model}`;
    },
  },
  watch: {
    currentDeviceFilters: function (filters) {
      this.$emit("filtersChanged", filters);
    },
  },
};
</script>
