<template>
  <v-select
    color="#f60076"
    outlined
    v-model="selectedItem"
    :items="computedFilteredItems"
    :label="label"
    item-text="name"
    item-value="value"
    dense
    clearable
  >
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            color="#f60076"
            v-model="searchTerm"
            placeholder="Search"
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
import EventBus from "../components/EventBus";
export default {
  data() {
    return {
      selectedItem: null,
      searchTerm: "",
    };
  },
  props: {
    items: Array,
    label: String,
  },
  mounted: function () {
    EventBus.$on("resetGeneralFilterForm", () => {
      this.selectedItem = null;
    });
  },
  computed: {
    // Compute the filtered items based on search text
    computedFilteredItems() {
      if (this.selectedItem) {
        return this.items.filter((item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else return this.items;
    },
  },
  watch: {
    selectedItem: function (item) {
      this.$emit("selectedItemChanged", item);
    },
  },
  methods: {},
};
</script>
