import axios from "axios";

const api = axios.create({
    //baseURL: "http://172.28.43.83:8080/gateway",
    baseURL: "../gateway",
    headers: {
        "Content-type": "application/json"
    }
});

api.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

export default api;

//json server: npx json-server --port 4002 --watch test-rest.json


