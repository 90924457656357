<template>
  <v-data-table
    :headers="headers"
    :items="cohorts"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:item.num_patients="{ item }">
      <template>
        <v-chip small color="#f60076" dark>
          {{ item.num_patients }}
        </v-chip>
      </template>
    </template>
    <template v-slot:item.actions="{ item }">
      <template>
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, index) in actionsList"
              :key="index"
              @click="handleAction(item.id, action.title)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ action.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
    <template v-slot:footer.prepend>
      <v-btn small color="#f60076" class="white--text">Add new</v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Number of patients", value: "num_patients" },
        { text: "Created date", value: "date_created" },
        { text: "Last edited", value: "date_edited" },
        { text: "Actions", value: "actions" },
      ],
      cohorts: [
        {
          id: 1,
          name: "Females over 30",
          num_patients: 159,
          date_created: "01-01-2023",
          date_edited: "10-01-2023",
        },
        {
          id: 2,
          name: "All patients",
          num_patients: 159,
          date_created: "01-01-2023",
          date_edited: "10-01-2023",
        },
        {
          id: 3,
          name: "Single/divorced males",
          num_patients: 159,
          date_created: "01-01-2023",
          date_edited: "10-01-2023",
        },
        {
          id: 4,
          name: "Patients over 30",
          num_patients: 159,
          date_created: "01-01-2023",
          date_edited: "10-01-2023",
        },
        {
          id: 5,
          name: "Males under 50",
          num_patients: 159,
          date_created: "01-01-2023",
          date_edited: "10-01-2023",
        },
      ],
      actionsList: [
        { title: "Edit" },
        { title: "Delete" },
        { title: "Set as default" },
      ],
    };
  },
  methods: {
    handleAction(id, action) {
      if (action === "Edit") {
        console.log("Should edit cohort id: ", id);
      } else if (action === "Delete")
        console.log("Should delete cohort id: ", id);
      else if (action === "Default")
        console.log("Should set to default cohort id: ", id);
    },
  },
};
</script>
