<template>
  <v-card>
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
    <v-skeleton-loader type="table-row" :loading="loading" />
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
