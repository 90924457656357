<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="questionnaireData"
    item-key="order_in_app"
    class="elevation-1"
    show-select
  >
    <template v-slot:footer.prepend>
      <div style="width: 300px; position: relative">
        <div style="position: absolute; top: -20px">
          <v-select
            color="#f60076"
            full-width
            label="Select file format to export"
            :items="fileExportFormatItems"
            v-model="selectedFileExportFormat"
            outlined
            dense
          >
          </v-select>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  mounted: function () {},
  props: {
    questionnaireData: Array,
  },
  data() {
    return {
      selected: [],
      expanded: [],
      headers: [
        {
          text: "Questionnaire code",
          value: "code",
        },
        { text: "Questionnaire name", value: "name" },
        {
          text: "Disease type",
          value: "disease_type",
        },
        { text: "Number of questions", value: "number_of_questions" },
        { text: "Order in app", value: "order_in_app" },
      ],
      fileExportFormatItems: ["CSV", "Excel"],
      selectedFileExportFormat: null,
    };
  },
  methods: {},
};
</script>
