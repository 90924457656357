<template>
  <v-data-table :headers="headers" :items="cohortData" item-key="id">
    <template v-slot:item.filter_criteria="{ item }">
      <template v-if="Object.keys(item.filter_criteria).length > 0">
        <div
          v-for="(criteria, index) in Object.keys(item.filter_criteria)"
          :key="index"
        >
          <span>{{ criteria }}:</span>&nbsp;&nbsp;

          <template v-if="Array.isArray(item.filter_criteria[criteria])">
            <span
              v-for="(value, index) in item.filter_criteria[criteria]"
              :key="index"
            >
              '{{ value }}', </span
            ><br />
          </template>
          <template v-else>
            {{ item.filter_criteria[criteria] }}
          </template>
        </div>
      </template>
      <template v-else> none </template>
    </template>
    <template v-slot:item.num_of_patients="{ item }">
      <template>
        <v-chip small color="#f60076" dark>
          {{ item.num_of_patients }}
        </v-chip>
      </template>
    </template>
    <template v-slot:footer.prepend>
      <div style="width: 300px; position: relative">
        <div style="position: absolute; top: -20px">
          <v-select
            color="#f60076"
            full-width
            label="Select file format to export"
            :items="fileExportFormatItems"
            v-model="selectedFileExportFormat"
            outlined
            dense
          >
          </v-select>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  mounted: function () {},
  props: {
    cohortData: Array,
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Cohort name",
          value: "cohort_name",
        },
        {
          text: "Filter criteria",
          value: "filter_criteria",
        },
        { text: "Created", value: "created" },
        {
          text: "Number of patients",
          value: "num_of_patients",
        },
      ],
      fileExportFormatItems: ["CSV", "Excel"],
      selectedFileExportFormat: null,
    };
  },
  methods: {},
};
</script>
