<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="deviceData"
    show-select
    item-key="serial_number"
    class="elevation-1"
  >
    <template v-slot:footer.prepend>
      <div style="width: 300px; position: relative">
        <div style="position: absolute; top: -20px">
          <v-select
            full-width
            label="Select file format to export"
            :items="fileExportFormatItems"
            v-model="selectedFileExportFormat"
            outlined
            dense
            color="#f60076"
          >
          </v-select>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  mounted: function () {},
  props: {
    deviceData: Array,
  },
  data() {
    return {
      selected: [],
      expanded: [],
      headers: [
        {
          text: "Hospital center",
          value: "center_name",
        },
        {
          text: "Category",
          value: "category",
        },
        { text: "Brand", value: "brand" },
        {
          text: "Model",
          value: "model",
        },
        { text: "Internal Device Code", value: "internal_device_code" },
        { text: "Serial number/Mac address", value: "serial_number" },
        { text: "Status", value: "status" },
      ],
      fileExportFormatItems: ["CSV", "Excel"],
      selectedFileExportFormat: null,
    };
  },
  methods: {},
};
</script>
