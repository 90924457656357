<template>
  <div class="dashboard-container">
    <DetailedInfoPanel
      v-if="detailedInfoDialog"
      :dialog="detailedInfoDialog"
      :activeUserInfo="activeUserInfo"
      :detailedInfoData="detailedInfoData"
      @closeDialog="detailedInfoDialog = false"
    />

    <template>
      <v-tabs
        v-model="activeTab"
        grow
        background-color="#F8BBD0"
        color="#f60076"
        height="40"
      >
        <v-tab
          :class="index === 3 ? 'collectiveEA' : ''"
          v-for="(item, index) in mainTabs"
          :key="index"
        >
          <template v-slot:default>
            <span :style="{ color: index === 3 ? 'white' : 'white' }">
              {{ item }}
            </span>
          </template>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="(item, index) in mainTabs" :key="index">
          <template v-if="index === 0">
            <div class="px-5 text-center filter-container">
              <FilterButtonsVue
                :filterPanelCollapsed="IMFCollapsed"
                filterPanelName="IMF"
                @toggleFilterPanel="toggleFilterPanel"
                @reset="resetFilterForm"
                @refresh="refreshData"
              />

              <v-expand-transition>
                <template v-if="!IMFCollapsed">
                  <GeneralFilterFormVue
                    :filtersMetadata="filtersMetadata"
                    @filtersChanged="handleGeneralFiltersChanged"
                  />
                </template>
              </v-expand-transition>
            </div>
            <div style="border: 1px solid lightgray">
              <patient-table
                v-if="!loadingPatientData"
                :patientData="patientData"
                @openDetailedInfo="openDetailedInfo"
              />
              <skeleton-loader v-else :loading="loadingPatientData" />
            </div>
          </template>
          <template v-else-if="index === 1">
            <div class="px-5 text-center filter-container">
              <FilterButtonsVue
                :filterPanelCollapsed="DMCollapsed"
                filterPanelName="DM"
                @toggleFilterPanel="toggleFilterPanel"
                @reset="resetFilterForm"
                @refresh="refreshData"
              />
              <v-expand-transition>
                <div v-if="!DMCollapsed">
                  <DevicesFilterFormVue
                    :deviceMetadata="deviceMetadata"
                    @filtersChanged="handleDeviceFiltersChanged"
                  />
                </div>
              </v-expand-transition>
            </div>
            <div style="border: 1px solid lightgray">
              <device-table
                v-if="!loadingDeviceData"
                :deviceData="deviceData"
              />
              <skeleton-loader v-else :loading="loadingDeviceData" />
            </div>
            <div class="text-center">
              <v-btn
                color="#f60076"
                class="white--text my-2 mx-2"
                @click="addDeviceDialog = true"
              >
                Add device
              </v-btn>
              <v-btn
                color="#f60076"
                class="white--text my-2 mx-2"
                @click="removeDevice"
              >
                Remove device
              </v-btn>
            </div>
          </template>
          <template v-else-if="index === 2">
            <div class="px-5 text-center filter-container">
              <FilterButtonsVue
                :filterPanelCollapsed="QCCollapsed"
                filterPanelName="QC"
                @toggleFilterPanel="toggleFilterPanel"
                @reset="resetFilterForm"
                @refresh="refreshData"
              />
              <v-expand-transition>
                <div v-if="!QCCollapsed">
                  <QuestionnairesFilterFormVue />
                </div>
              </v-expand-transition>
            </div>
            <div style="border: 1px solid lightgray">
              <questionnaire-table :questionnaireData="questionnaireData" />
            </div>
            <div class="text-center">
              <v-btn
                color="#f60076"
                class="white--text my-2 mx-2"
                @click="addQuestionnaireDialog = true"
              >
                Add Questionnaire
              </v-btn>
              <v-btn
                color="#f60076"
                class="white--text my-2 mx-2"
                @click="editQuestionnaire"
              >
                Edit Questionnaire
              </v-btn>
              <v-btn
                color="#f60076"
                class="white--text my-2 mx-2"
                @click="removeQuestionnaire"
              >
                Remove Questionnaire
              </v-btn>
            </div>
          </template>
          <template v-else-if="index === 3">
            <div style="border: 1px solid lightgray">
              <cohort-table
                v-if="!loadingCohortData"
                :cohortData="cohortData"
              />
            </div>
            <div class="text-center">
              <v-btn
                color="#f60076"
                class="white--text my-2"
                @click="addCohortDialog = true"
              >
                Add Cohort
              </v-btn>
            </div>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <v-dialog v-model="addDeviceDialog" persistent max-width="550">
      <v-card class="text-center">
        <v-toolbar flat color="#f5f5f5" dense
          ><span>Add device</span></v-toolbar
        >
        <v-card-text class="mt-10">
          <AddDeviceVue />
        </v-card-text>
        <v-btn color="pink darken-1" class="white--text" @click="addDevice">
          Add Device
        </v-btn>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="pink darken-1" text @click="addDeviceDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addQuestionnaireDialog" persistent max-width="550">
      <v-card class="text-center">
        <v-toolbar flat color="#f5f5f5" dense
          ><span>Add questionnaire</span></v-toolbar
        >
        <v-card-text class="mt-10">
          <AddQuestionnaireVue />
        </v-card-text>
        <v-btn
          color="pink darken-1"
          class="white--text mx-2"
          @click="addQuestionnaire"
        >
          Add Question
        </v-btn>
        <v-btn
          color="pink darken-1"
          class="white--text mx-2"
          @click="submitQuestionnaire"
        >
          Submit
        </v-btn>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="pink darken-1"
            text
            @click="addQuestionnaireDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cohortListDialog" persistent max-width="650">
      <v-card class="text-center">
        <v-toolbar flat color="#f5f5f5" dense><span>Cohorts</span></v-toolbar>
        <v-card-text class="mt-10">
          <cohorts-list />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="pink darken-1" text @click="cohortListDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addCohortDialog" persistent max-width="650">
      <v-card class="text-center">
        <v-toolbar flat color="#f5f5f5" dense
          ><span>Add cohort</span></v-toolbar
        >
        <v-card-text class="mt-10">
          <add-cohort-form
            :filters="cohortMetadata"
            @saveCohort="handleSaveCohort"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="pink darken-1" text @click="addCohortDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PatientTable from "../components/PatientTable.vue";
import DeviceTable from "../components/DeviceTable.vue";
import QuestionnaireTable from "../components/QuestionnaireTable.vue";
import DetailedInfoPanel from "../components/DetailedInfoPanel.vue";
import AddDeviceVue from "../components/AddDevice.vue";
import AddQuestionnaireVue from "../components/AddQuestionnaire.vue";
import GeneralFilterFormVue from "../components/GeneralFilterForm.vue";
import DevicesFilterFormVue from "../components/DevicesFilterForm.vue";
import QuestionnairesFilterFormVue from "../components/QuestionnairesFilterForm.vue";
import FilterButtonsVue from "../components/FilterButtons.vue";
import CohortsList from "../components/CohortsList.vue";
import Services from "../rest/Services";
import EventBus from "../components/EventBus";
import SkeletonLoader from "../components/TableSkeletonLoader.vue";
import CohortTable from "../components/CohortTable.vue";
import AddCohortForm from "../components/AddCohortForm.vue";

export default {
  name: "Dashboard",

  components: {
    PatientTable,
    DeviceTable,
    QuestionnaireTable,
    DetailedInfoPanel,
    AddDeviceVue,
    AddQuestionnaireVue,
    GeneralFilterFormVue,
    DevicesFilterFormVue,
    QuestionnairesFilterFormVue,
    FilterButtonsVue,
    CohortsList,
    SkeletonLoader,
    CohortTable,
    AddCohortForm,
  },
  mounted() {
    this.getFilterMetadata();
    this.getPatientData();
    this.getDeviceData();
    this.getCohortData();
    this.getCohortMetadata();
  },
  data: function () {
    return {
      loadingPatientData: true,
      loadingDeviceData: true,
      loadingCohortData: true,
      filterDialog: false,
      detailedInfoDialog: false,
      patientData: [],
      cohortData: [],
      activeTab: "Individual Monitoring",
      mainTabs: [
        "Individual Monitoring",
        "Device management",
        "Questionnaire configuration",
        "Collective exploratory analytics",
      ],

      deviceData: [],
      questionnaireData: [
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257760",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257761",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257762",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257763",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257764",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257765",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257766",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257767",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257768",
        },
        {
          code: "TRACKER",
          name: "Garmin",
          disease_type: "Vivoactive 4",
          number_of_questions: "GARMIN_TURIN11",
          order_in_app: "61K257769",
        },
      ],
      activeUserInfo: null,
      addDeviceDialog: false,
      addQuestionnaireDialog: false,
      cohortListDialog: false,
      someText: "Lorem ipsum",
      IMFCollapsed: true,
      DMCollapsed: true,
      QCCollapsed: true,
      filtersMetadata: {},
      deviceMetadata: {},
      detailedInfoData: {},
      currentGeneralFilters: {},
      currentDeviceFilters: {},
      addCohortDialog: false,
      cohortMetadata: {},
    };
  },
  methods: {
    handleGeneralFiltersChanged(filters) {
      this.currentGeneralFilters = filters;
    },
    handleDeviceFiltersChanged(filters) {
      this.currentDeviceFilters = filters;
    },
    getFilterMetadata: function () {
      Services.getFilterMetadata().then((response) => {
        this.filtersMetadata = response.data.general_info_filters;
        this.deviceMetadata = response.data.device_management_filters;
      });
    },
    getCohortMetadata: function () {
      Services.getCohortMetadata().then((response) => {
        this.cohortMetadata = response.data;
      });
    },
    getPatientData: function () {
      this.loadingPatientData = true;
      Services.getPatientData().then((response) => {
        this.patientData = response.data.data;
        this.loadingPatientData = false;
      });
    },
    getPatientFilteredData: function (params) {
      this.loadingPatientData = true;
      Services.getPatientFilteredData(params).then((response) => {
        this.patientData = response.data.data;
        this.loadingPatientData = false;
      });
    },
    getDeviceData: function () {
      this.loadingDeviceData = true;
      Services.getDeviceData().then((response) => {
        this.deviceData = response.data.devices;
        this.loadingDeviceData = false;
      });
    },
    getDeviceFilteredData: function (params) {
      this.loadingDeviceData = true;
      Services.getDeviceFilteredData(params).then((response) => {
        this.deviceData = response.data.devices;
        this.loadingDeviceData = false;
      });
    },
    getCohortData: function () {
      this.loadingCohortData = true;
      Services.getCohortData().then((response) => {
        this.cohortData = response.data;
        this.loadingCohortData = false;
      });
    },
    handleSaveCohort: function (filters) {
      const keys = Object.keys(filters);
      keys.forEach((key) => {
        if (Array.isArray(filters[key] && filters[key].length === 0)) {
          filters[key] = null;
        }
      });
      Services.saveCohort(filters)
        .then((response) => {
          console.log("Success", response);
          EventBus.$emit("clearAddCohortFilters");
          this.getCohortData();
          this.addCohortDialog = false;
        })
        .catch((error) => console.log("error :>> ", error));
    },

    closeDialog: function () {
      this.dialog = false;
    },
    openDetailedInfo(patientCode) {
      this.activeUserInfo = patientCode;
      Services.getDetailedData(patientCode).then((response) => {
        this.detailedInfoData = response.data;
        this.detailedInfoDialog = true;
      });
      // fetch("http://localhost:4002/data")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.detailedInfoData = data;
      //     this.detailedInfoDialog = true;
      //   });
    },

    addDevice: function () {
      console.log("add device");
    },
    removeDevice: function () {
      console.log("remove device");
    },
    addQuestionnaire: function () {
      console.log("add Questionnaire");
    },
    editQuestionnaire: function () {
      console.log("edit Questionnaire");
    },
    removeQuestionnaire: function () {
      console.log("remove Questionnaire");
    },
    submitQuestionnaire: function () {
      console.log("submit questionnaire");
    },
    toggleFilterPanel: function (panelName) {
      switch (panelName) {
        case "IMF":
          this.IMFCollapsed = !this.IMFCollapsed;
          break;
        case "DM":
          this.DMCollapsed = !this.DMCollapsed;
          break;
        case "QC":
          this.QCCollapsed = !this.QCCollapsed;
          break;
        default:
          break;
      }
    },
    resetFilterForm: function (panelName) {
      switch (panelName) {
        case "IMF":
          this.getPatientData();
          EventBus.$emit("resetGeneralFilterForm");
          break;
        case "DM":
          this.getDeviceData();
          EventBus.$emit("resetDeviceFilterForm");
          break;
        case "QC":
          this.QCCollapsed = !this.QCCollapsed;
          break;
        default:
          break;
      }
    },
    refreshData: function (panelName) {
      switch (panelName) {
        case "IMF":
          this.getPatientFilteredData(this.currentGeneralFilters);
          break;
        case "DM":
          this.getDeviceFilteredData(this.currentDeviceFilters);
          break;
        case "QC":
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scopend>
.dashboard-container {
  position: relative;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}
.filter-btn {
  position: absolute;
  top: 65px;
  left: -70px;
}
.collectiveEA {
  color: #f60076;
}

/* CSS for the transition */
.collapse-transition-enter-active,
.collapse-transition-leave-active {
  transition: height 0.5s;
}
.collapse-transition-enter,
.collapse-transition-leave-to {
  height: 0;
  overflow: hidden;
}
.filter-container {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
</style>
