<template>
  <div class="mx-10">
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Questionnaire Code"
        outlined
        v-model="questionnaireCode"
      >
      </v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Questionnaire Name"
        outlined
        v-model="questionnaireName"
      >
      </v-text-field>
    </v-row>
    <v-row>
      <v-select
        color="#f60076"
        v-model="diseaseType"
        :items="diseaseTypeItems"
        label="Disease type"
        outlined
      ></v-select>
    </v-row>
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Number of questions"
        outlined
        v-model="numOfQuestions"
      >
      </v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        color="#f60076"
        placeholder="Order in app"
        outlined
        v-model="orderInApp"
      >
      </v-text-field>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      diseaseType: null,
      diseaseTypeItems: ["ALS", "MS"],
      questionnaireCode: "",
      questionnaireName: "",
      numOfQuestions: "",
      orderInApp: "",
    };
  },
};
</script>
