<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!filterPanelCollapsed"
          icon
          v-bind="attrs"
          v-on="on"
          @click="reset"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
      <span>Reset</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          large
          icon
          class="mx-10"
          @click="toggleFilterPanel"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filters</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!filterPanelCollapsed"
          icon
          v-bind="attrs"
          v-on="on"
          @click="refresh"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </template>
      <span>Refresh</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    filterPanelCollapsed: Boolean,
    filterPanelName: String,
  },
  methods: {
    toggleFilterPanel() {
      this.$emit("toggleFilterPanel", this.filterPanelName);
    },
    refresh() {
      this.$emit("refresh", this.filterPanelName);
    },
    reset() {
      this.$emit("reset", this.filterPanelName);
    },
  },
};
</script>
