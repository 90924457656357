import api from "./api";
class Services {
    login(params) {
        return api.post("/account/login", params);

    }
    getPatientData() {
        return api.get("/patient/adminDashboard/individual/data");
    }
    getPatientFilteredData(params) {
        return api.post("/patient/adminDashboard/individual/filter-data", params);
    }
    getDeviceFilteredData(params) {
        return api.post("/patient/adminDashboard/devices/filter-devices", params);
    }
    getFilterMetadata() {
        return api.get("/patient/adminDashboard/filter-metadata")
    }
    getDetailedData(params) {
        return api.get(`/patient/adminDashboard/detailed?accessCode=${params}`)
    }
    getDeviceData() {
        return api.get("/patient/adminDashboard/devices")
    }
    getCohortData() {
        return api.get("/patient/adminDashboard/cohorts")
    }
    getCohortMetadata() {
        return api.get("/patient/adminDashboard/cohorts/metadata")
    }
    saveCohort(params) {
        return api.post("/patient/adminDashboard/cohorts/add", params)
    }

}
export default new Services();
