<template>
  <v-dialog persistent v-model="dialog" max-width="870">
    <v-toolbar flat color="#f5f5f5" dense
      ><v-toolbar-title
        >Detailed info for: <b>{{ activeUserInfo }}</b></v-toolbar-title
      ><v-spacer></v-spacer
      ><v-btn icon @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn></v-toolbar
    >
    <v-card>
      <v-tabs
        v-model="activeTab"
        fixed-tabs
        background-color="#F8BBD0"
        height="40px"
        color="#f60076"
      >
        <v-tab v-for="(item, index) in tabs" :key="index">
          <span style="color: white">{{ item }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card>
            <v-card-text>
              <template v-if="index === 0">
                <DetailedInfoDeviceVue :devices="detailedInfoData.devices" />
              </template>
              <template v-else-if="index === 1">
                <DetailedInfoQuestionnaireVue
                  :questionnaireData="detailedInfoData.questionnaire"
                />
              </template>
              <template v-else-if="index === 2"> </template>
              <template v-else-if="index === 3">
                <v-card width="400" style="margin: 0 auto" class="mt-10">
                  <v-card-text>
                    <v-row>
                      <v-col cols="7">
                        <b><span>Account status:</span></b
                        ><br />
                        <b><span>Last connection:</span></b
                        ><br />
                        <b><span>Retrospective available:</span></b
                        ><br />
                        <b><span>Status in the study:</span></b
                        ><br />
                        <b><span>Vital status:</span></b>
                      </v-col>
                      <v-col cols="5">
                        <span>{{
                          detailedInfoData.user_info.account_status
                        }}</span
                        ><br />
                        <span>{{
                          detailedInfoData.user_info.last_connection
                        }}</span
                        ><br />
                        <span>{{
                          detailedInfoData.user_info.retrospective_available
                        }}</span
                        ><br />
                        <span>{{
                          detailedInfoData.user_info.status_in_the_study
                        }}</span
                        ><br />
                        <span>{{
                          detailedInfoData.user_info.vital_status
                        }}</span
                        ><br />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="pink darken-1" text @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DetailedInfoDeviceVue from "./DetailedInfoDevice.vue";
import DetailedInfoQuestionnaireVue from "./DetailedInfoQuestionnaire.vue";
export default {
  components: {
    DetailedInfoDeviceVue,
    DetailedInfoQuestionnaireVue,
  },
  props: {
    dialog: Boolean,
    activeUserInfo: String,
    detailedInfoData: Object,
  },
  mounted: function () {
    this.detailedDialog = this.dialog;
  },
  data: function () {
    return {
      detailedDialog: false,
      activeTab: null,
      tabs: ["Devices", "Questionnaires", "Ai models", "User info"],
    };
  },
  methods: {
    closeDialog: function () {
      this.$emit("closeDialog");
    },
  },
};
</script>
