var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cohorts,"items-per-page":5},scopedSlots:_vm._u([{key:"item.num_patients",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"small":"","color":"#f60076","dark":""}},[_vm._v(" "+_vm._s(item.num_patients)+" ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actionsList),function(action,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleAction(item.id, action.title)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.title))])],1)],1)}),1)],1)]]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"#f60076"}},[_vm._v("Add new")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }